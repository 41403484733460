<template>
    <div class="c-widget--text c-widget--full">
        <div v-html="data.text"></div>
    </div>
</template>

<script>
    export default {
        name: 'pageText',
        props: {
            data: Object
        }
    }
</script>